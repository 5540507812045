@charset "utf-8";
/* CSS Document */

/* Simple reset*/
* {
    margin: 0; padding: 0;
}

body {
    background: #81C4D1;
}

.compass {
    width: 400px;
    height: 400px;
    background-color: #F3F3F3;
    border-radius: 100%;
    background-image: -webkit-linear-gradient(top, #F7F7F7, #ECECEC);
    position: relative;
    margin: -130px auto;
    transform: scale(0.4);
}

.compass-inner {
    width: 340px;
    height: 340px;
    background-color: #3D3D3D;
    border-radius: 100%;
    position: relative;
    left: 27.5px;
    top: 27.5px;
    border: 3px solid #C5C5C5;
}

.main-arrow {
    height: 100%;
    width: 30px;
    left: 155px;
    position: relative;
    padding-top: 5px;
    box-sizing:border-box;
    transition: 1s all;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 165px solid #EF5052;
    position: relative;
}

.arrow-down {
    -webkit-transform: rotate(180deg); /* Safari and Chrome */
    width: 0;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 165px solid #F3F3F3;
    position: relative;
}

.north {
    font-size: 32px;
    color: #FFF;
    position: absolute;
    left: 155px;
    top: 10px;
}

.east {
    font-size: 32px;
    color: #FFF;
    position: absolute;
    left: 290px;
    top: 150px;
}

.west {
    font-size: 32px;
    color: #FFF;
    position: absolute;
    left: 20px;
    top: 150px;
}

.south {
    font-size: 32px;
    color: #FFF;
    position: absolute;
    left: 155px;
    top: 280px;
}


@media (max-width: 600px) {
    .compass {
        width: 200px;
        height: 200px;
        text-align: center;
    }

    .compass-inner {
        width: 170px;
        height: 170px;
        left: 13px;
        top: 13px;
    }

    .main-arrow {
        width: 30px;
        left: 78px;
        width: 15px;
        padding-top: 3px;
    }

    .arrow-up {
        border-bottom: 82.5px solid #EF5052;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
    }

    .arrow-down {
        border-bottom: 82.5px solid #F3F3F3;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
    }

    .north {
        left: 78px;
        top: 10px;
        font-size: 16px;
    }

    .south {
        font-size: 16px;
        left: 78px;
        top: 140px;
    }

    .east {
        font-size: 16px;
        top: 72.5px;
        left: 145px;
    }

    .west {
        font-size: 16px;
        left: 10px;
        top: 72.5px;
    }
}


@media (max-width: 600px) {
    .compass {
        width: 200px;
        height: 200px;
        text-align: center;
    }

    .compass-inner {
        width: 170px;
        height: 170px;
        left: 13px;
        top: 13px;
    }

    .main-arrow {
        width: 30px;
        left: 78px;
        width: 15px;
        padding-top: 3px;
    }

    .arrow-up {
        border-bottom: 82.5px solid #EF5052;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
    }

    .arrow-down {
        border-bottom: 82.5px solid #F3F3F3;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
    }

    .north {
        left: 78px;
        top: 10px;
        font-size: 16px;
    }

    .south {
        font-size: 16px;
        left: 78px;
        top: 140px;
    }

    .east {
        font-size: 16px;
        top: 72.5px;
        left: 145px;
    }

    .west {
        font-size: 16px;
        left: 10px;
        top: 72.5px;
    }
}

@media (max-width: 600px) {
    .compass {
        width: 200px;
        height: 200px;
        text-align: center;
    }

    .compass-inner {
        width: 170px;
        height: 170px;
        left: 13px;
        top: 13px;
    }

    .main-arrow {
        width: 30px;
        left: 78px;
        width: 15px;
        padding-top: 3px;
    }

    .arrow-up {
        border-bottom: 82.5px solid #EF5052;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
    }

    .arrow-down {
        border-bottom: 82.5px solid #F3F3F3;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
    }

    .north {
        left: 78px;
        top: 10px;
        font-size: 16px;
    }

    .south {
        font-size: 16px;
        left: 78px;
        top: 140px;
    }

    .east {
        font-size: 16px;
        top: 72.5px;
        left: 145px;
    }

    .west {
        font-size: 16px;
        left: 10px;
        top: 72.5px;
    }
}

@media (max-width: 600px) {
    .compass {
        width: 200px;
        height: 200px;
        text-align: center;
    }

    .compass-inner {
        width: 170px;
        height: 170px;
        left: 13px;
        top: 13px;
    }

    .main-arrow {
        width: 30px;
        left: 78px;
        width: 15px;
        padding-top: 3px;
    }

    .arrow-up {
        border-bottom: 82.5px solid #EF5052;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
    }

    .arrow-down {
        border-bottom: 82.5px solid #F3F3F3;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
    }

    .north {
        left: 78px;
        top: 10px;
        font-size: 16px;
    }

    .south {
        font-size: 16px;
        left: 78px;
        top: 140px;
    }

    .east {
        font-size: 16px;
        top: 72.5px;
        left: 145px;
    }

    .west {
        font-size: 16px;
        left: 10px;
        top: 72.5px;
    }
}