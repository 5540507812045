.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-btn-primary,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #51bfa4 !important;
  border-color: #51bfa4 !important;
}
.ant-checkbox-checked::after {
  border: 1px solid #51bfa4 !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #327867;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #327867 !important;
  border-color: #51bfa4 !important;
}
.ant-modal-title , .ant-table-cell , .ant-select-item-option-content , .ant-tabs-tab , .profil-info , .ant-modal-body, .ant-dropdown-menu{
  font-weight: 600 !important;
  font-size: 12px !important;
}
.ant-popover-inner-content{
  border-radius: 50px !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid #51bfa4 !important;
}
.expandingIcon {
  color: #327867 !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #51bfa4 !important;
  opacity: 1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-radio-button-wrapper:hover {
  background: #51bfa4;
  color: white !important;
}

.ant-btn-primary:hover {
  background-color: #327867 !important;
  border-color: #327867 !important;
}

.ant-btn:not(.ant-btn-primary, .btn-danger , .btn-warning ):hover,
.ant-btn:not(.ant-btn-primary , .btn-danger  , .btn-warning ):focus {
  color: #51bfa4 !important;
  background-color: #fff;
  border-color: #51bfa4 !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #327867 !important;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #51bfa4 !important;
}
tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td{
  background: unset !important;
}
.ant-table-tbody > tr > td {
  overflow-wrap: unset !important;
  word-break: break-word;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table{
  margin: 0 !important;
}
.text-primary {
  color: #51bfa4;
}

.text-danger {
  color: #d9534f;
}

.ant-modal-header,
.ant-modal-footer {
  border: none !important;
}

.ant-modal-body {
  padding: 8px 24px !important;
}

.ant-switch-checked {
  background-color: #51bfa4 !important;
}
.ant-layout.ant-layout-has-sider{
  padding-bottom: 0 !important;
}
.ant-menu-item-selected > a,
.ant-menu-submenu-selected {
  color: #51bfa4 !important;
}

.ant-menu-item > a:hover,
.ant-menu-submenu-selected:hover {
  color: #51bfa4 !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: unset !important;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper{
  box-shadow: none !important;
  -webkit-box-shadow: none!important;
}
.ant-drawer-body {
  padding: 0px !important;
}

.ant-drawer-header {
  padding: 0px !important;
  border-bottom: none !important;
}

.ant-table-thead > tr > th {
  background: white !important;
}

.ant-pagination {
  margin: unset !important;
  padding: 16px !important;
}
.ant-table-pagination.ant-pagination{
  align-items: center;
}
.ant-pagination-options{
  margin-left: 10px !important;
}

.ant-select-selector {
  margin: 0 0 !important;
  height: 40px !important;
  line-height: 22px !important;
  padding: 4px 11px !important;
  display: flex !important;
  align-items: center;
}
.ant-picker {
  height: 40px;
}
/*.ant-input-password{*/
/*  padding: 0px 11px !important;*/
/*}*/
.ant-input-number{
  width: 100% !important;
}
.ant-select-selection--single,
.ant-input-number,
.ant-input-number-input {
  height: 40px !important;
}
.form-lang{
  position: relative;
}
.form-lang .ant-legacy-form-item-children .input-lang {
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 14px;
  color: lightgrey;
}
.form-lang .input-lang {
  display: inline-block;
  position: absolute;
  top:9px;
  right: 14px;
  color: lightgrey;
}
.form-lang .input-lang.btm {
  bottom: 9px;
  top: unset;
}

.ant-input {
  margin-top: 0px !important;
}
textarea.ant-input {
  height: auto !important;
}
.form-lang .ant-legacy-form-item-children input , .form-lang .ant-form-item input  {
  padding-right: 40px;
}

.ant-table-wrapper .ant-select-selection__rendered {
  margin: 0 10px 0 5px !important;
  height: 28px !important;
  line-height: 30px !important;
  padding: 4px 15px 0 5px !important;
  align-items: center;
}

.ant-table-wrapper .ant-select-selection--single {
  height: 36px !important;
}

.ant-table-wrapper .ant-select-arrow {
  margin-top: -4px;
}

.ant-legacy-form-item {
  margin-bottom: 5px !important;
}

/*customize tabs*/
.ant-tabs-tab .ant-tabs-tab-btn {
  color: #51bfa4 !important;
}
.ant-tabs-nav{
  background: white !important;
}

.ant-tabs{
  height: 100vh;
}

.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
  margin: 0 !important;
}
.tab-section .ant-tabs-ink-bar {
  background-color: #51bfa4;
}

.tab-section .ant-tabs-nav-list .ant-tabs-tab:hover,
.ant-tabs-nav-list .ant-tabs-tab-active {
  color: #51bfa4 !important;
  transition: 0.3s ease-in-out;
}

.tab-section .ant-tabs-nav-list .ant-tabs-tab:hover {
  background: #e6f7ff;
}
.ant-table-tbody > tr.ant-table-row:hover > td{
  background: #e6f7ff !important;
}
.ant-col{
  flex: 0 0 100%;
}
@media only screen and (min-width: 540px) {
  .tab-section .ant-tabs-nav-list {
    width: 100%;
    display: flex;
  }
  .tab-section .ant-tabs-tab {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
.ant-tabs-nav-operations{
  display: none !important;
}
.tab-section .ant-tabs-tab {
  padding: 12px !important;
  margin: 0;
}
@media only screen and (max-width: 540px){
  .tab-section .ant-tabs-tab {
    width: 100%;
  }
}

.ant-table-body {
  margin: 0px !important;
}
.ant-table-tbody td,
.ant-table-thead th {
  padding: 8px 20px !important;
}
.tab-section .ant-table-wrapper::after {
  content: unset !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #d9534f !important;
  border-color: #d43f3a !important;
}

.btn-warning {
  color: #fff !important;
  background-color: #f0ad4e !important;
  border-color: #eea236 !important;
}

.btn-danger:hover,
.btn-warning:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.customtable {
  border-collapse: collapse;
  width: 100%;
}
@media (min-width: 991px){
  .customtable {
    font-size: 14px;
  }
}
@media (max-width: 991px){
  .customtable {
    font-size: 15px;
  }
}
@media (max-width: 420px){
  .customtable {
    font-size: 13px;
  }
}
.customtable tr {
  border: 1px solid #ddd;
}
.customtable tr td:first-child{
  text-align: left;
}
.customtable tr td:last-child{
  text-align: right;
}

.customtable td , th {
  padding: 10px;
}
.customtable tr:hover td {
  background-color: #e6f7ff;
}

.customtable th {
  font-weight: bold !important;
  padding-top: 12px;
  padding-bottom: 12px;
}

.padModal .ant-modal-content {
  padding-bottom: 70px;
}

.padModal .ant-form-item-label {
  margin-top: 5px !important;
}
.padModal .has-error .ant-form-explain,
.padModal .has-error .ant-legacy-form-explain,
.padModal .has-error .ant-legacy-form-split ,
.padModal .has-error .ant-form-split {
  position: absolute;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 40px !important;
}

.ant-select-item-option-grouped{
  color: black !important;
}

.v-hidden{
  visibility: hidden;
}

.form-upload .ant-upload-list {
  position: absolute;
  bottom: 15px;
}


.d-none{
  display: none;
}
.cursor-pointer{
  cursor: pointer;
}
.inteqrations p {
  font-size: 13px;
}
.topmenu-img{
  height: 35px;
  margin-top: 25px;
  margin-bottom: 15px;
}

