.leaflet-container {
  width: 100%;
  height: 100%;
}
.leaflet-marker-icon {
  /*height: 13px !important;*/
  /*width: 13px !important;*/
  border-radius: 50%;
}
.esri-ui-top-right{
  top: 90px !important;
}
.selects{
  z-index: 1000 !important;
  right: 34px;
  top: 155px;
  position: absolute !important;
}


.esri-popup__feature-menu-observer{
  position: absolute;
  z-index: 12010120;
  background: red;
  height: 100px;
  widows: 100px;
}

.risc{
  background:  #51bfa4;
  text-align: center;
  color: white;
  width: 100%;
  cursor: pointer;
  padding: 10px 20px;
  transition: 0.3s ease-in-out;
}
.risc:hover{
  background: #327867 !important;
  transition: 0.3s ease-in-out;
}

.map-circle{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid lightgray;
}
.map-circle.warning{
  background: orange;
}
.map-circle.error {
  background: red;
}
.map-circle.blue {
  background: blue;
}
.riskmodal .ant-modal-content .ant-modal-body{
  max-height: 1000px !important;
}


.leaflet-marker-icon {
  transform: rotate(20deg);

}

.esri-layer-list{
  display: none !important;
}
.ant-spin{
  color: #54c0a5 !important;
}


.ant-tabs.tab-map{
  height: unset !important;
}